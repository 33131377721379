import Axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import IosDownloadOutline from 'react-ionicons/lib/IosDownloadOutline';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MessageField from '../../components/MessageField/MessageField';
import MessagesList from '../../components/MessageList/MessagesList';
import SplashScreen from '../../components/SplashScreen/SplashScreen';
import OfflineModal from '../../components/OfflineModal/OfflineModal';
import { sendMessage } from '../../redux/Messages/actions';
import './ChatView.scss';
import ChatHeader from '../../components/ChatHeader/ChatHeader';
import PollModal from '../../components/PollModal/PollModal';


const ChatView = ({ chat, dispatch, user, external=false }) => {
  const { t, i18n } = useTranslation();

  const lang = useSelector((state) => state.chat.lang);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  const { clientId, roomId } = user;
  const onDrop = useCallback(
    async (files) => {
      if (files.length > 0) {
        const formData = new FormData();
        formData.append('clientId', clientId);
        formData.append('roomId', roomId);
        for (const file of files) {
          formData.append('chatFile', file);
        }
        const result = await Axios.post(`${process.env.REACT_APP_BACKEND}/file`, formData);
        if (result.data) {
          dispatch(sendMessage({ text: '', attachments: result.data }));
        }
      }
    },
    [dispatch, clientId, roomId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });
  if (!chat.loaded) {
    return <SplashScreen />;
  }

  return (
    <div {...getRootProps()} className="chatHolder" >
      <ChatHeader external={external} />
      {isDragActive && (
        <div className="dropZone">
          <IosDownloadOutline fontSize="62px" color="#999"></IosDownloadOutline>
          <div>{t('DRAG_FILES_HERE')}</div>
        </div>
      )}
      <input {...getInputProps()} />
      <MessagesList external={external} />
      <MessageField />
      <OfflineModal />
      <PollModal />
    </div>
  );
};

const stateToProps = (state) => ({ chat: state.chat, user: state.user });

export default connect(stateToProps)(ChatView);
