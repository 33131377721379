import { get, throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Lightbox from 'react-awesome-lightbox';
import { Item, Menu } from 'react-contexify';
import {Puff} from 'react-loader-spinner';
import { connect } from 'react-redux';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import { useTranslation } from 'react-i18next';

import { fetchMessage, sendMessage, editMessage, deleteMessage } from '../../redux/Messages/actions';
import Message from '../Message/Message';
import styles from './MessagesList.module.scss';
import { readNew, updateLastSeen } from '../../redux/Chat/actions';
import { useInSight } from '../../utils/useInSight';

import 'react-contexify/dist/ReactContexify.css';
import 'react-awesome-lightbox/build/style.css';

export const MENU_ID = 'MESSAGE_MENU';

const DIRECTIONS = {
    DOWN: 'down',
    UP: 'up'
}

const MessagesList = ({ messages, dispatch, color, lastSeenOp, lastSeen, hasNew, total, external = false }) => {
    const { t } = useTranslation();
    
    const [loading, setLoading] = useState(false);
    const [imageFullscreen, setImageFullscreen] = useState();
    const [messageInEdit, setInEdit] = useState();

    const ref = useRef();
    const listRef = useRef();
    const inSight = useInSight(ref);

    const flatList = Object.entries(messages).reverse();

    const noMore = useMemo(() => {
        if (!total) return false;
        return (flatList.length) >= total;
    }, [total, flatList])

    /**
     * Local functions
     */
    const _scroll = useCallback(() => {
        let log = document.getElementById('messagesList');
        log.scrollTop = log.scrollHeight;
    }, []);

    const nextBatch = throttle(() => {
        if (noMore) return;
        setLoading(true);
        dispatch(fetchMessage(flatList.length));
        return true;
    }, 500);

    const reply = (data) => {
        dispatch(sendMessage({ text: data.payload }));
    };

    const onEditComplete = (message) => {
        setInEdit(undefined);
        if (message) {
            dispatch(editMessage(message));
        }
    };

    const scrollBottom = () => {
        _scroll();
    };

    const _handleScroll = (e) => {
        const listHeight = e.target.offsetHeight + 35;
        if (e.target.scrollHeight < listHeight) return;
        const scrolledHeightFromTop = e.target.scrollHeight + (e.target.scrollTop - listHeight);
        if (scrolledHeightFromTop < 65 && !loading) {
            nextBatch();
        }
    };

    const _handleItemClick = ({ event, props }) => {
        const { message } = props;
        switch (event.currentTarget.id) {
            case 'editMessage':
                setInEdit(message._id);
                break;
            case 'deleteMessage':
                dispatch(deleteMessage(message._id));
                break;
            default:
                break;
        }
    };

    /**
     * Effects
     */
    useEffect(() => {
        if (inSight) {
            if (flatList.length > 0) {
                const [, lastMessage] = flatList[0];
                if (!lastSeen || new Date(lastSeen) < new Date(lastMessage.created)) {
                    dispatch(updateLastSeen({ roomId: lastMessage.roomId, time: new Date() }));
                }
            }
            dispatch(readNew());
        }
    }, [inSight, messages, flatList, lastSeen, dispatch]);

    useEffect(() => {
        if (flatList.length === 0) return;
        // const [, lastMessage] = flatList[0];
        // if (lastMessage.from === lastMessage.roomId) _scroll();
        if (inSight) _scroll();
        setLoading(false);
    }, [_scroll, flatList, inSight]);

    useEffect(() => {
        setTimeout(() => {
            _scroll();
        }, 1000);
    }, [_scroll]);

    /** Prevent body scroll and bubbling */
    useEffect(() => {
        const cancelWheel = e => {
            if (listRef.current) {
                const { scrollHeight, offsetHeight, scrollTop } = listRef.current;
                const { deltaY } = e;
                const direction = deltaY >= 0 ? DIRECTIONS.DOWN : DIRECTIONS.UP;

                switch (direction) {
                    case DIRECTIONS.DOWN:
                        if (scrollTop === 0) return e.preventDefault();
                        break;

                    case DIRECTIONS.UP:
                        const diff = scrollHeight + scrollTop - offsetHeight;
                        if (diff === 0) return e.preventDefault();
                        break;
                    default:
                        break;
                }                
            }
            return;
        }
        document.body.addEventListener('wheel', cancelWheel, { passive: false })
        document.body.addEventListener('touchmove', cancelWheel, { passive: false })
        return () => {
            document.body.removeEventListener('wheel', cancelWheel)
            document.body.removeEventListener('touchmove', cancelWheel)
        }
    }, [listRef])

    return (
        <>
            <div className={styles.messagesList} id="messagesList" ref={listRef} onScroll={_handleScroll}>
                <div ref={ref}></div>
                {hasNew && (
                    <div className={styles.newMessagesPill} onClick={scrollBottom}>
                        <IosArrowDown />
                        &nbsp;
                        <span>Нові повідомлення</span>
                    </div>
                )}

                {flatList.map(([key, message], index) => {
                    let last = true;
                    if (flatList[index + 1]) {
                        last = flatList[index + 1][1].from !== message.from || (flatList[index + 1][1].messageContext &&
                            message.messageContext && flatList[index + 1][1].messageContext.personId !== message.messageContext.personId);
                    }
                    return (
                        <Message
                            last={last}
                            key={key}
                            {...message}
                            reply={reply}
                            setImageFullscreen={setImageFullscreen}
                            inEdit={message._id === messageInEdit}
                            onEditComplete={onEditComplete}
                            message={message}
                            lastSeenOp={lastSeenOp}
                            external={external}
                        />
                    );
                })}

                {noMore && (
                    <div className={styles.noMore}></div>
                )}
                {loading && (
                    <div className={styles.loading}>
                        <Puff color={color} height={20} width={20} />
                    </div>
                )}
            </div>
            <Menu id={MENU_ID}>
                <Item id="editMessage" onClick={_handleItemClick}>
                    {t('EDIT')}
                </Item>
                <Item id="deleteMessage" onClick={_handleItemClick}>
                    {t('DELETE')}
                </Item>
            </Menu>
            {imageFullscreen && <Lightbox image={imageFullscreen} onClose={() => setImageFullscreen()} />}
        </>

    );
};

const stateToProp = (state) => ({
    messages: state.messages,
    color: get(state, 'chat.color'),
    lastSeenOp: state.chat.lastSeenOp,
    lastSeen: state.chat.lastSeen,
    hasNew: state.chat.hasNew,
    total: state.chat.total,
});
export default connect(stateToProp)(MessagesList);
