import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ChatHeader.scss';
import IosClose from 'react-ionicons/lib/IosClose';
import { closeChat } from '../../redux/Chat/actions';
import Avatar from '../Avatar/Avatar';

const ChatHeader = ({external=false}) => {
  const color = useSelector((state) => get(state, 'chat.color', '#51718d'));
  const title = useSelector((state) => get(state, 'chat.title', ''));
  const logo = useSelector((state) => get(state, 'chat.logo'));
  const agent = useSelector((state) => get(state, 'chat.agent'));

  const dispatch = useDispatch();
  
  const _renderHeader = () => {
    if (agent) {
      return (<>
        <Avatar user={agent} style={{ marginLeft: 10, marginTop: 15 }} />
        <div className="name" style={{alignItems: agent.position ? 'flex-start' : 'center'}}>{agent.name}
          <div className="position">{agent.position}</div>
        </div>
        
      </>)
    }

    return (
      <>
        <div className="logo">{logo && <img src={logo} alt="Company logo" />}</div>
        <div className="title">{title}</div>
      </>
    )
  }

  return (
    <div style={{ backgroundColor: color }} className="chatHeader">
      {_renderHeader()}
      {!external && <div className="btns" style={{justifySelf: 'end', marginLeft: 'auto'}}>
        <IosClose className="close" fontSize="42px" color="#FFF" onClick={() => dispatch(closeChat())}></IosClose>
      </div>}
    </div>
  );
};

export default ChatHeader;
