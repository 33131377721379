import Axios from 'axios';
import { SET, SET_FP, SET_UDATA } from './types';

// Action creators

export const setUser = user => ({
  type: SET,
  payload: user,
});

export const setUdata = user => ({
  type: SET_UDATA,
  payload: user,
});

export const setFp = fp => ({
  type: SET_FP,
  payload: fp,
});

// Thunks functions

export const sendPersonalData = (payload) => {
  return (dispatch) => {
    Axios.patch(
      process.env.REACT_APP_BACKEND + "/entity/Person/" + payload.person,
      payload.personalData
    ).then(res => {
      dispatch(setUser({...payload.personalData}));
    }).catch(err => {})
  };
}
