import React, { useEffect, useState } from 'react';
import { sendMessage, sendTyping, clearTyping } from '../../redux/Messages/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './MessagesField.scss';
import TextareaAutosize from 'react-autosize-textarea';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import IosPaperPlane from 'react-ionicons/lib/IosPaperPlane';
import IosAttach from 'react-ionicons/lib/IosAttach';

const MessageField = ({ dispatch, user, placeholder }) => {
  const { t } = useTranslation();

  const { clientId, roomId } = user;
  const [message, setMessage] = useState('');
  
  const id = useSelector((state) => get(state, 'user.id', null));
  const name = useSelector((state) => get(state, 'user.name', null));

  useEffect(() => {
    if (message) {
      dispatch(sendTyping({ text: message.replace(/^\n|\n$/g, '') }));
    } else {
      dispatch(clearTyping())
    }
  }, [dispatch, message]);

  const send = () => {
    const cleanMessage = message.replace(/^\n|\n$/g, '');

    if (cleanMessage !== '') {
      dispatch(clearTyping())
      dispatch(sendMessage({ text: cleanMessage, sender: {personId: id, name: name} }));
      setMessage('');
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      send();
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const fileSelected = async e => {
    const files = [...e.target.files];

    if (files.length > 0) {
      const formData = new FormData();
      formData.append('clientId', clientId);
      formData.append('roomId', roomId);
      for (const file of files) {
        formData.append('chatFile', file);
      }
      const result = await axios.post(`${process.env.REACT_APP_BACKEND}/file`, formData);
      if (result.data) {
        dispatch(sendMessage({ text: '', attachments: result.data, sender: {personId: id, name: name} }));
      }
    }
  }
  return (
    <div className="messagesField">
      <div className="input">
        <TextareaAutosize
          name=""
          id=""
          cols="25"
          rows={1}
          value={message}
          onKeyDown={handleKeyDown}
          placeholder={placeholder === 'YOUR_MESSAGE' ? t('YOUR_MESSAGE') : placeholder}
          className={isMobile ? 'mobile' : ''}
          onBlur={e => {
            dispatch(clearTyping())
          }}
          onFocus={e => {
            dispatch(sendTyping({ text: message.replace(/^\n|\n$/g, '') }))
          }}
          onChange={e => {
            setMessage(e.target.value.replace(/^\n/g, ''));
          }}
        ></TextareaAutosize>
      </div>
      <label className="button" htmlFor="chatFileInput">
        <IosAttach fontSize="24px" color="#51718d"></IosAttach>
        <input type="file" onChange={fileSelected} hidden id="chatFileInput" multiple />
      </label>
      <div className="button">
        <IosPaperPlane fontSize="24px" color="#51718d" onClick={send}></IosPaperPlane>
      </div>
    </div>
  );
};

const stateToProps = state => ({ user: state.user, placeholder: state.chat.placeholder });

export default connect(stateToProps)(MessageField);
