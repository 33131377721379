import socketIOClient from 'socket.io-client';
import { chatLoaded, pollRequest, setActive, setOffline, setLastSeenOp, setLastSeen, hasNew, setTotal, setAgent } from '../Chat/actions';
import { PAGE_DATA, POLL_ANSWER, UPDATE_LAST_SEEN } from '../Chat/types';
import {
    fetchMessage,
    mergeMessages,
    receiveMessage,
    removeMessage,
    updateMessage,
} from '../Messages/actions';
import { CLEAR_TYPING, DELETE, EDIT, FETCH, SEND, TYPING } from '../Messages/types';
import { addNotification } from '../Push/actions';
import { setUser } from '../User/actions';
import { SET_FP } from '../User/types';
import { DISCONNECT_SOCKET } from './types';

let _socket;

const getSocket = () => {
    if (_socket) {
        return _socket;
    } else {
        return null;
    }
};

const setSocket = ({ fp, externalPerson }, store) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let clientId = urlParams.get('client');
    const domain = urlParams.get('domain');
    const proactive_dialog = urlParams.get('proactive_dialog');
    const proactive_forced = urlParams.get('proactive_forced');
    let name = urlParams.get('name') || undefined;
    
    if (externalPerson) {
        clientId = externalPerson.clientId;
        name = externalPerson.name;
    }

    if (!fp) return;

    let roomId = `s${fp}_${clientId}`;
    if (domain) {
        roomId = `${roomId}_${domain}`;
    }
    if (externalPerson) {
        roomId = externalPerson.roomId;
    }
    const nsp = `/s/chat/${clientId}`;
    
    let query = { roomId, nsp, domain, proactive_dialog, name, proactive_forced };

    const {
        chat: { page },
    } = store.getState();

    if (page) {
        const { pageUrl, pageTitle, name, email } = page;
        query = Object.assign(query, { pageUrl, pageTitle, name, email });
    }

    _socket = socketIOClient(`${process.env.REACT_APP_BACKEND}${nsp}`, {
        query,
        transports: ['websocket'],
    });
    if (externalPerson) {
        store.dispatch(setUser({name: null, phone: null, email: null, ...externalPerson, id: externalPerson._id}));
    } else {
        store.dispatch(setUser({ clientId, roomId }));
    }

    _socket.on('update', (message) => {
        store.dispatch(updateMessage(message));
    });

    _socket.on('message', (message) => {
        store.dispatch(receiveMessage(message));
        store.dispatch(hasNew());
        if (message.messageContext) {
            if (message.messageContext.role === 'agent') {
                store.dispatch(setAgent(message.messageContext))
            }
        }
        
        if (message.from !== roomId || (externalPerson && message.messageContext && 
            message.messageContext.personId !== externalPerson._id
        )) {
            store.dispatch(
                addNotification({
                    text: message.text,
                    tag: 'new-message',
                    title: 'Нове повідомлення',
                    type: 'newMessage',
                    data: message,
                })
            );
        }
    });

    _socket.on('list', (data) => {
        const {messages, lastSeenTimeOp, lastSeenTimeClient, total} = data;

        const {
            chat: { loaded },
        } = store.getState();
        if (!loaded) {
            store.dispatch(chatLoaded());
        }
        store.dispatch(mergeMessages(messages));
        store.dispatch(setLastSeenOp(lastSeenTimeOp));
        store.dispatch(setLastSeen(lastSeenTimeClient));
        store.dispatch(setTotal(total));
    });

    _socket.on('connect', (message) => {
        store.dispatch(setActive());
        setTimeout(() => {
            store.dispatch(fetchMessage());
        }, 1000);
    });

    _socket.on('offline', (data) => {
        if (data) {
            const { domain: opDomain } = data;
            if (opDomain === domain) {
                store.dispatch(setOffline(true));
            }
        } else {
            store.dispatch(setOffline(true));
        }
    });

    _socket.on('online', ({ domains }) => {
        if (domains && domains.length > 0) {
            if (domains.indexOf(domain) !== -1) {
                store.dispatch(setOffline(false));
            } else {
                // store.dispatch(setOffline(true));
            }
        } else {
            store.dispatch(setOffline(false));
        }
    });

    _socket.on('update_message', (message) => {
        console.log(message);
        store.dispatch(updateMessage(message));
    });

    _socket.on('message_removed', (message) => {
        store.dispatch(removeMessage(message));
    });

    _socket.on('poll_request', (pollData) => {
        store.dispatch(pollRequest(pollData));
    });

    _socket.on('last_seen_time_op', (time) => {
        store.dispatch(setLastSeenOp(time));
    });

    _socket.on('last_seen_time', (time) => {
        store.dispatch(setLastSeen(time));
    })
};

const socketMiddleware = (store) => {
    return (next) => (action) => {
        if (!getSocket() && action.type !== SET_FP) {
            return next(action);
        }
        const socket = getSocket();
        switch (action.type) {
            case SEND:
                socket.emit('message', {
                    ...action.payload,
                });
                break;
            case TYPING:
                socket.emit('typing', action.payload);
                break;
            case CLEAR_TYPING:
                socket.emit('clear_typing');
                break;
            case FETCH:
                socket.emit('fetch', {
                    ...action.payload,
                });
                break;
            case DISCONNECT_SOCKET:
                socket.disconnect();
                break;
            case SET_FP:
                setSocket(action.payload, store);
                break;
            case PAGE_DATA:
                socket.emit('update_user_data', {
                    ...action.payload,
                });
                break;
            case DELETE:
                socket.emit('remove_message', action.payload);
                break;
            case EDIT:
                socket.emit('update_message', { message: action.payload });
                break;
            case POLL_ANSWER:
                socket.emit('answer_poll', action.payload);
                break;
            case UPDATE_LAST_SEEN:
                socket.emit('update_last_seen_time', action.payload);
                break;
            default:
                break;
        }

        return next(action);
    };
};

export default socketMiddleware;
