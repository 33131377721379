import React, { useState, useRef, useEffect } from 'react';
import { useContextMenu } from 'react-contexify';
import { Animated } from 'react-animated-css';
import TextareaAutosize from 'react-autosize-textarea';
import { isMobile } from 'react-device-detect';
import IosCheckmark from 'react-ionicons/lib/IosCheckmark';
import IosClose from 'react-ionicons/lib/IosClose';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import './Message.scss';
import { MENU_ID } from '../MessageList/MessagesList';
import { useCallback } from 'react';

const Message = ({
    message,
    text,
    from,
    roomId,
    to,
    created,
    last,
    attachments,
    quick_replies,
    reply,
    setImageFullscreen,
    inEdit,
    onEditComplete,
    lastSeenOp,
    external=false
}) => {
    const id = useSelector((state) => get(state, 'user.id', null));
    const [messageText, setMessageText] = useState(text);
    const handleText = useCallback(
        () => {
            const r = /(?:www|https?)[^\s]+/g;
            const links = text.match(r);
            if (!links) return { text, ext: [] };
    
            let html = text.replace(
                /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
                '<a href="$1" target="_blank">$1</a>'
            );
            return { text: html, ext: links };
        },
        [text],
    )
    const [htmlContent, setHtml] = useState(handleText);

    const [seen, setSeen] = useState(false);

    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const editRef = useRef();

    useEffect(() => {
        if (inEdit) {
            editRef.current.focus();
        }
    }, [inEdit]);

    useEffect(() => {
        setHtml(handleText);
    }, [handleText, text]);

    useEffect(() => {
        setSeen(lastSeenOp && new Date(lastSeenOp) >= new Date(created) ? true : false);
    }, [lastSeenOp, created]);

    const isOwner = () => {
        return external ? 
        (message.from === roomId && message.messageContext && message.messageContext.personId === id) :
        message.from === roomId;
    }

    if (!text && !attachments) {
        return null;
    }
    const newMessage = (new Date() - new Date(created)) / 1000 / 60;
    const direction = isOwner() ? ' out' : ' in';

    function handleContextMenu(event) {
        if (!inEdit && isOwner() ) {
            event.preventDefault();
            show(event, {
                props: {
                    message,
                },
            });
        }
    }

    const _mapImages = (item, i) => {
        if (item.type !== 'image') {
            return null;
        }
        return (
            <img
                style={{ display: 'block' }}
                src={item.url}
                alt={item.filename}
                key={`image_${i}`}
                onClick={() => setImageFullscreen(item.url)}
            />
        );
    };

    const saveMessage = () => {
        if (typeof onEditComplete === 'function') {
            onEditComplete({ ...message, text: messageText });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            saveMessage();
            e.stopPropagation();
            e.preventDefault();
        }
    };

    /**
     * Renders
     */

    return (
        <Animated
            animationIn={isOwner() ? 'bounceInRight' : 'bounceInLeft'}
            animationInDuration={1000}
            isVisible={true}
            animateOnMount={newMessage < 1}
            className={direction}
        >
            <div className="message-wrap">
                <div
                    className={'message' + direction + (last ? ' last' : '')}
                    onContextMenu={handleContextMenu}
                >
                {external && message.messageContext && (from !== roomId ||
                message.messageContext.personId !== id) && message.messageContext && 
                message.messageContext.personId !== id && message.messageContext.name ? 
                <div className='message-from'>{message.messageContext.name}</div> : null}
                    {attachments && attachments.map(_mapImages)}
                    {attachments &&
                        attachments.map(
                            (item, key) =>
                                item.type === 'document' && (
                                    <a style={{ display: 'block' }} key={`file_${key}`} href={item.url}>
                                        {item.originalname}
                                    </a>
                                )
                        )}
                    {inEdit ? (
                        <div>
                            <div className="text-right">
                                <IosClose
                                    onClick={() => onEditComplete(false)}
                                    color={'#00000'}
                                    height="50px"
                                    width="50px"
                                />
                                <IosCheckmark
                                    onClick={saveMessage}
                                    color={'#00000'}
                                    height="50px"
                                    width="50px"
                                />
                            </div>

                            <TextareaAutosize
                                ref={editRef}
                                name="messageText"
                                id="messageText"
                                cols="46"
                                rows={1}
                                value={messageText}
                                className={isMobile ? 'mobile' : ''}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => {
                                    setMessageText(e.target.value.replace(/^\n/g, ''));
                                }}
                            ></TextareaAutosize>
                        </div>
                    ) : (
                        <span>
                            {htmlContent.text.split('\n').map((item, i, texts) => (
                                <span key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                    {texts.length > 1 && <br />}
                                </span>
                            ))}
                        </span>
                    )}
                    {quick_replies.length > 0 && (
                        <div className="btns">
                            {quick_replies.map(
                                (item, key) =>
                                    item.content_type === 'text' && (
                                        <button
                                            className="quickReply btn"
                                            key={key}
                                            onClick={() => reply(item)}
                                        >
                                            {item.title}
                                        </button>
                                    )
                            )}
                        </div>
                    )}
                    {seen && isOwner() ? (
                        <div className="seenCheck">
                            <IosCheckmark color={'#388e3c'} />
                        </div>
                    ) : null}
                </div>
            </div>
        </Animated>
    );
};

export default Message;
