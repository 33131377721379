import React, { useState, useEffect, Fragment } from 'react';
import IosAlertOutline from 'react-ionicons/lib/IosAlertOutline';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { sendPersonalData } from '../../redux/User/actions';
import styles from './PersonalInfoModal.module.scss';

const PersonalInfoModal = () => {
  const [personalData, setPersonalData] = useState({
    name: '', email: '', phone: ''
  });
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const name = useSelector((state) => get(state, 'user.name', null));

  useEffect(() => {
    console.log(name);
    if (!name || name === '') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [name]);

  const {id} = useParams();
  const dispatch = useDispatch();

  const submitForm = (e) => {
    e.preventDefault();
    if (!personalData.name || personalData.name.trim() === '') {
      setError("Вкажіть Ваше ім'я");
      return;
    } else {
      setError(null);
    }
    dispatch(sendPersonalData({ personalData, person: id }));
  };

  const renderForm = () => (
    <Fragment>
      <div style={{width: 220}}>Вкажіть Ваші дані для продовження</div>
      <br />
      <form onSubmit={submitForm}>
        {error && <div className={styles.required}>{error}</div>}
        <div>
          <label htmlFor="name">Ім'я<span className={styles.required}>*</span></label>
          <input id="name" name="name" value={personalData.name} 
          onChange={(e) => setPersonalData({...personalData, name: e.target.value})} />
        </div>
        <div>
          <label htmlFor="email">E-mail</label>
          <input id="email" name="email" value={personalData.email} 
          onChange={(e) => setPersonalData({...personalData, email: e.target.value})} />
        </div>
        <div>
          <label htmlFor="phone">Телефон</label>
          <input id="phone" name="phone" value={personalData.phone} 
          onChange={(e) => setPersonalData({...personalData, phone: e.target.value})} />
        </div>
        <div className={styles.buttons}>
          <button>Надіслати</button>
        </div>
      </form>
    </Fragment>
  );

  return (
    <ReactModal ariaHideApp={false} isOpen={open} className={styles.chatModal} contentLabel="Особисты дані">
      <div className={styles.attention}>
        <IosAlertOutline style={{ background: '#FFF' }} fontSize="48px" color="#51718d"></IosAlertOutline>
      </div>
      {renderForm()}
    </ReactModal>
  );
};

export default PersonalInfoModal;
