import React from 'react'

const SettingsView = () => {
    return (
        <div>
            This is Settings View
        </div>
    )
}

export default SettingsView
