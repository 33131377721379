import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import ChatView from '../ChatView/ChatView';
import './ExternalView.scss';
import PersonalInfoModal from '../../components/PersonalInfoModal/PersonalInfoModal';

const ExternalView = () => {
  const bgColor = useSelector((state) => get(state, 'chat.backgroundColor', 'transparent'));

  return <div className='externalContainer' style={{backgroundColor: bgColor}}>
    <div className='externalWrapper'>
      <ChatView external={true} />
      <PersonalInfoModal />
    </div>
  </div>
}

export default ExternalView;