export const CLEAR = 'clear';
export const CHAT_READY = 'chat_ready';
export const SEND_WINDOW_MESSAGE = 'send_window_message';
export const CHAT_SETTINGS = 'chat_settings';
export const PAGE_DATA = 'page_data';
export const CHAT_LOADED = 'chat_loaded';
export const SET_OFFLINE = 'set_offline';
export const CLOSE_CHAT = 'close_chat';
export const POLL_REQUEST = 'poll_request';
export const POLL_ANSWER = 'poll_answer';
export const SET_LAST_SEEN_OP = 'set_last_seen_op';
export const SET_LAST_SEEN = 'set_last_seen';
export const UPDATE_LAST_SEEN = 'update_last_seen';
export const SET_NEW = 'set_new';
export const SET_TOTAL = 'set_total';
export const SET_CURRENT_AGENT = 'set_current_agent';