import {combineReducers} from 'redux';
import messages from './Messages/reducers';
import user from './User/reducers';
import chat from './Chat/reducers';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const urlParams = new URLSearchParams(window.location.search);
const clientId = urlParams.get('client');

const persistConfig = {
    key: `chat_${clientId}`,
    storage: storage,
    whitelist: ['agent']
};
const persistedChat  = persistReducer(persistConfig, chat);

const reducers = combineReducers({
    messages,
    user,
    chat: persistedChat
});

export {reducers}