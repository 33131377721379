import React from 'react'
import './Avatar.scss';

const colors = {
    blue: '#005792',
    indigo: '#7f3ddb',
    purple: '#1A86D0',
    pink: '#e54e9a',
    red: '#FD5F00',
    orange: '#ff7d47',
    yellow: '#FDA700',
    green: '#68ca66',
    teal: '#21AE8C',
    cyan: '#17a2b8'
};

const Avatar = ({ user: { photo, color, name }, size = 45, style }) => {
    const getIntials = () => {
        const takeTwo = str => str.split(' ').slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('');

        if (!name) {
            return '';
        }
        return takeTwo(name);;
    }

    const _renderAvatar = () => {
        if (photo) return <img src={photo} alt="user avatar" />;

        return getIntials();
    }

    return (
        <div className="avatar" style={{...style,
            height: size + 'px',
            width: size + 'px',
            minWidth: size + 'px',
        }}>
            <div className="imageWrapper" style={
                color ? {
                    fontSize: size / 3 + 'px',
                    backgroundColor: colors[color]
                } :
                    { fontSize: size / 3 + 'px' }}>
                {_renderAvatar()}
            </div>
        </div>
    )
}

export default Avatar
