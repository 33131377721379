import Axios from "axios";
import { ADD_NOTIFICATION } from "./actions";

const applicationServerKey = process.env.REACT_APP_VAPID_PUB;

export const isSupported = () =>
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window

const registerNotificationsSW = async () => {
    if ('serviceWorker' in navigator) {
        return await navigator.serviceWorker.register('/notificationsSW.js');
    } else {
        throw new Error('Service workers are not supported.');
    }
}

export const askPermission = async () => new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
        resolve(result);
    });

    if (permissionResult) {
        permissionResult.then(resolve, reject);
    }
});

const handleMessage = async ({ data }) => {
    if (data.messageType !== 'notification') {
        return;
    }
}

const sw = navigator.serviceWorker;
let registration;

export const subscribeToPush = async (user) => {
    registration = await registerNotificationsSW();

    const subscrition = await subscribeUser(registration);
    await Axios.patch(`${process.env.REACT_APP_BACKEND}/user/subscribePush`,
        { pushSubscription: JSON.stringify(subscrition), roomId: user.roomId });

    await sw.ready;
    sw.addEventListener('message', handleMessage);
}

const subscribeUser = async (serviceWorkerRegistration) => {
    return await serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey
    });
}


const PushMiddleware = store => {
    const ping = () => {
        try {
            const audio = new Audio('/light-562.mp3');
            audio.play().catch(err => {});
        } catch (error) { }
    }

    const subscribeRoutine = async (withPush = false) => {
        registration = await registerNotificationsSW();

        /**
         * Subscribe operator to offline push
         */
        if (withPush) {
            const { chat: { user }} = store.getState();
            const subscrition = await subscribeUser(registration);
            await Axios.patch(`${process.env.REACT_APP_BACKEND}/user/subscribePush`,
                { pushSubscription: JSON.stringify(subscrition), roomId: user.roomId });   
        }

        await sw.ready;
        sw.addEventListener('message', handleMessage);
    }

    if (isSupported() && typeof Notification !== 'undefined') {        
        if (Notification.permission === 'granted') {
            subscribeRoutine().catch(err => {});
        } else {
            // askPermission().then(function (permissionResult) {
            //     if (permissionResult !== 'granted') {
            //         throw new Error('Chat functionality requires permission for notifications');
            //     } else {
            //         subscribeRoutine(true).catch(err => {});
            //     }
            // }).catch(({ message }) => {
            //     console.log(message);
            // });
        }
    }

    const receivePushNotification = async (event) => {
        const { chat: { muted } } = store.getState();
        if (muted) {
            return;
        }

        ping();
        const { image, tag, data, text, title } = event;

        const options = {
            data,
            body: text,
            vibrate: [200, 100, 200],
            tag: tag,
            image: image,
            actions: [{ action: "Деталі", title: "Переглянути" }],
            icon: "/apple-touch-icon.png",
            renotify: true
        };

        if (registration && registration.showNotification) {
            await registration.showNotification(title, options);
        }
    }

    return next => action => {
        switch (action.type) {
            case ADD_NOTIFICATION:
                receivePushNotification(action.payload);
                break;
            default:
                break;
        }
        return next(action);
    };
};

export default PushMiddleware;