import { SET, SET_FP, SET_UDATA, CLEAR } from './types';

const initialState = {
  clientId: null,
  roomId: null,
  fp: null,
  id: null,
  name: null,
  email: null,
  phone: null,
  udata: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return {...state, ...action.payload};
    case SET_UDATA:
      return {...state, udata: action.payload};
    case SET_FP:
      return {...state, fp: action.payload.fp};
    case CLEAR:
      return {};
    default:
      return state;
  }
};
