import {
    CHAT_READY,
    CHAT_SETTINGS,
    CLEAR,
    PAGE_DATA,
    CHAT_LOADED,
    SET_OFFLINE,
    POLL_REQUEST,
    SET_LAST_SEEN_OP,
    SET_LAST_SEEN,
    UPDATE_LAST_SEEN,
    SET_NEW,
    SET_TOTAL,
    SET_CURRENT_AGENT,
} from './types';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const initialState = {
    ready: false,
    loaded: false,
    page: null,
    offline: false,
    color: urlParams.get('color') || '#51718d',
    pollData: null,
    lastSeenOp: 0,
    lastSeen: 0,
    hasNew: false,
    total: undefined,
    agent: null,
    placeholder: urlParams.get('placeholder') || 'YOUR_MESSAGE',
    backgroundColor: 'transparent',
    lang: undefined
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHAT_SETTINGS:
            return Object.assign({}, state, action.payload);
        case CHAT_READY:
            return Object.assign({}, state, { ready: true });
        case CHAT_LOADED:
            return Object.assign({}, state, { loaded: true });
        case PAGE_DATA:
            return Object.assign({}, state, { page: action.payload });
        case SET_OFFLINE:
            return Object.assign({}, state, { offline: action.payload });
        case CLEAR:
            return { ready: false, page: null };
        case POLL_REQUEST:
            return Object.assign({}, state, { pollData: action.payload });
        case SET_LAST_SEEN_OP:
            return Object.assign({}, state, { lastSeenOp: action.payload });
        case SET_LAST_SEEN:
            return Object.assign({}, state, { lastSeen: action.payload });
        case UPDATE_LAST_SEEN:
            return Object.assign({}, state, { lastSeen: action.payload.time });
        case SET_NEW:
            return Object.assign({}, state, { hasNew: action.payload });
        case SET_TOTAL:
            return Object.assign({}, state, { total: action.payload });
        case SET_CURRENT_AGENT:
            return Object.assign({}, state, { agent: action.payload });
        default:
            return state;
    }
};
