import { setUdata, setUser } from "../User/actions";
import { setSettings } from "./actions";
import { CHAT_READY, CLOSE_CHAT, PAGE_DATA, SEND_WINDOW_MESSAGE } from "./types";

const chatMiddleware = store => {
    if (window) {
        window.addEventListener(
            'message',
            function (e) {
                const { command, payload } = e.data;
                if (command) {
                    switch (command) {
                        case 'userData':
                            store.dispatch(setUser(payload));
                            break;
                        case 'settings':
                            store.dispatch(setSettings(payload));
                            break;
                        case 'page':
                            store.dispatch({
                                type: PAGE_DATA,
                                payload
                            });
                            store.dispatch(setUdata(payload));
                            break;
                        default:
                            break;
                    }
                }
            },
            false
        );
    }
    return next => action => {
        switch (action.type) {
            case SEND_WINDOW_MESSAGE:
                const { command, data } = action.payload;
                window.parent.postMessage([command, data], '*');
                break;
            case CHAT_READY:
                window.parent.postMessage(['command', 'appReady'], '*');
                break;
            case CLOSE_CHAT:
                window.parent.postMessage(['command', 'closeWidget'], '*');
                break;
            default:
                break;
        }

        return next(action);
    };
};

export default chatMiddleware;