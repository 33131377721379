// Action creators

import { UPDATE, RECEIVE, MESSAGES, MERGE, SEND, FETCH, SEND_FILES, TYPING, CLEAR_TYPING, REMOVE, DELETE, EDIT } from './types';

export const setMessages = messages => ({
  type: MESSAGES,
  payload: messages,
});

export const mergeMessages = messages => ({
  type: MERGE,
  payload: messages,
});

export const receiveMessage = message => ({
  type: RECEIVE,
  payload: message,
});

export const updateMessage = message => ({
  type: UPDATE,
  payload: message,
});

export const removeMessage = message => ({
  type: REMOVE,
  payload: message,
});

export const sendMessage = message => ({
  type: SEND,
  payload: message,
});

export const sendTyping = data => ({
  type: TYPING,
  payload: data
});

export const clearTyping = data => ({
  type: CLEAR_TYPING
});

export const sendFiles = files => ({
  type: SEND_FILES,
  payload: files,
});

export const fetchMessage = (skip = 0, limit = 50) => ({
  type: FETCH,
  payload: {skip, limit}
});

export const deleteMessage = messageId => ({
  type: DELETE,
  payload: messageId,
});

export const editMessage = message => ({
  type: EDIT,
  payload: message,
});

// Thunks functions
