import {
    CHAT_READY,
    CHAT_SETTINGS,
    CHAT_LOADED,
    SET_OFFLINE,
    CLOSE_CHAT,
    POLL_REQUEST,
    POLL_ANSWER,
    SET_LAST_SEEN_OP,
    SET_LAST_SEEN,
    UPDATE_LAST_SEEN,
    SET_NEW,
    SET_TOTAL,
    SET_CURRENT_AGENT
} from './types';

// Action creators

export const setActive = (user) => {
    return {
        type: CHAT_READY,
        payload: user,
    };
};

export const setSettings = (settings) => {
    return {
        type: CHAT_SETTINGS,
        payload: settings,
    };
};

export const chatLoaded = () => {
    return {
        type: CHAT_LOADED,
        payload: true,
    };
};

export const setOffline = (status) => {
    return {
        type: SET_OFFLINE,
        payload: status,
    };
};

export const closeChat = () => {
    return {
        type: CLOSE_CHAT,
    };
};

export const pollRequest = (pollData) => ({
    type: POLL_REQUEST,
    payload: pollData,
});

export const answerPoll = (pollData) => ({
    type: POLL_ANSWER,
    payload: pollData,
});

export const setLastSeenOp = (time) => ({
    type: SET_LAST_SEEN_OP,
    payload: time,
});

export const setLastSeen = (time) => ({
    type: SET_LAST_SEEN,
    payload: time,
});

export function updateLastSeen(payload) {
    return {
        type: UPDATE_LAST_SEEN,
        payload,
    };
}

export function hasNew() {
    return {
        type: SET_NEW,
        payload: true,
    };
}

export function readNew() {
    return {
        type: SET_NEW,
        payload: false,
    };
}

export function setTotal(payload) {
    return {
        type: SET_TOTAL,
        payload,
    };
}

export function setAgent(payload) {
    return {
        type: SET_CURRENT_AGENT,
        payload,
    };
}

// Thunks functions
