import React from 'react';
import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import './index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import App from './App';
import * as Sentry from "@sentry/react";
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import './i18n';
import { persistor, store } from './redux/store';
import SplashScreen from './components/SplashScreen/SplashScreen';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://82c0af7d4ca84ac8bee876464cca3816@o1070483.ingest.sentry.io/6066452"
  });

  console.log(`Build version: ${process.env.REACT_APP_CI_COMMIT_TAG}`);
  console.log("If you found some vulnerabilities or bugs please contact support team via email support@shark.chat.");
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={<SplashScreen />}>
            <Suspense fallback={null}>
              <App />
            </Suspense>
          </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
