import { ClientJS } from 'clientjs';
import Axios from 'axios';
import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { DefaultToastContainer, ToastProvider } from 'react-toast-notifications';
import './App.scss';
import { setSettings } from './redux/Chat/actions';
import { store } from './redux/store';
import { setFp } from './redux/User/actions';
import { defaultExternalSettings } from './utils/external';
import ChatView from './views/ChatView/ChatView';
import ExternalView from './views/ExternalView/ExternalView';
import SettingsView from './views/SettingsView';

export const history = createBrowserHistory();

const ToastContainer = (props) => <DefaultToastContainer style={{ zIndex: 9999 }} {...props} />;

function App(props) {
    const detectId = async () => {
        let person;

        const path = window.location.pathname.split('/');        
        if (path.length > 2 && path[1] === 'external') {
            try {
                const {data} = await Axios.patch(`${process.env.REACT_APP_BACKEND}/entity/Person/${path[2]}/getExternal`);
                if (data && data.person) {
                    person = data.person;
                }
                if (data && data.settings) {
                    store.dispatch(setSettings(data.settings));
                } else {
                    store.dispatch(setSettings(defaultExternalSettings));
                }
            } catch(error) {
                console.log(error);
            }
        }

        let visitorId;
        try {
            visitorId = localStorage.getItem('visitorId');
        } catch (error) {}

        if (!visitorId) {
            const client = new ClientJS();
            visitorId = client.getFingerprint();
            try {
                localStorage.setItem('visitorId', visitorId);
            } catch (error) {}
        }

        let deviceData;
        // try {
        //     deviceData = await getDeviceInfo()
        // } catch (error) {
        //     console.log(error);
        // }

        store.dispatch(setFp({ fp: visitorId, externalPerson: person, deviceData }));
    };
    useEffect(() => {
        if (process?.browser) {
            detectId();
        }
    }, []);

    return (
        <ToastProvider components={{ ToastContainer }} autoDismiss={true} autoDismissTimeout={3000}>
            <Router history={history}>
                <Switch>
                    <Route path="/settings" component={SettingsView} />
                    <Route path="/external/:id" component={ExternalView} />
                    <Route path="/" component={ChatView} />
                </Switch>
            </Router>
        </ToastProvider>
    );
}

export default App;
