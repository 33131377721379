import { RECEIVE, UPDATE, MESSAGES, INIT, MERGE, SEND, TYPING, CLEAR_TYPING, REMOVE, DELETE, EDIT } from './types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE:
      state[action.payload._id] = action.payload;
      return Object.assign({}, state);
    case UPDATE:
      return Object.assign({}, state, { [action.payload._id]: action.payload });
    case REMOVE:
      delete state[action.payload];
      const messages = {...state};
      return Object.assign({}, messages);
    case INIT:
    case MESSAGES:
      return Object.assign({}, action.payload);
    case MERGE:
      return Object.assign({}, action.payload, state);
    case TYPING:
    case CLEAR_TYPING:
    case SEND:
    case DELETE:
    case EDIT:
    default:
      return state;
  }
};
