export const SEND = 'send';
export const RECEIVE = 'receive';
export const UPDATE = 'update';
export const MESSAGES = 'messages';
export const INIT = 'init';
export const MERGE = 'merge';
export const FETCH = 'fetch';
export const SEND_FILES = 'send_files'
export const TYPING = 'typing';
export const REMOVE = 'remove';
export const CLEAR_TYPING = 'clear_typing';
export const DELETE = 'delete';
export const EDIT = 'edit';