import { get } from 'lodash';
import React from 'react';

import {ThreeDots} from 'react-loader-spinner';
import { useSelector } from 'react-redux';

import styles from './SplashScreen.module.scss';

const SplashScreen = () => {
    const color = useSelector(state => get(state, 'chat.color', '#51718d'));

    // const logo = useSelector(state => get(state, 'chat.logo'));
    // if (logo) {
    //     return (
    //         <div className={styles.splashScreen}>
    //             <img src={logo} alt="Company logo" className={styles.logo} />
    //         </div>
    //     )
    // }

    return (
        <div className={styles.splashScreen}>
            <ThreeDots
                color={color}
                height={100}
                width={100}
                className={styles.logo}
            />
        </div>
    )
}

export default SplashScreen
