import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducers } from './reducers';
import socketMiddleware from './Socket/middleware';
import chatMiddleware from './Chat/middleware';
import PushMiddleware from './Push/middleware';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['user']
};
const persistedReducer  = persistReducer(persistConfig, reducers);

const enhancer = composeWithDevTools(applyMiddleware(thunk, socketMiddleware, chatMiddleware, PushMiddleware));

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);
