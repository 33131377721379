import React, { useRef, useState, useEffect } from 'react';
import { Fragment } from 'react';
import IosAlertOutline from 'react-ionicons/lib/IosAlertOutline';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { sendMessage } from '../../redux/Messages/actions';
import { askPermission, isSupported, subscribeToPush } from '../../redux/Push/middleware';

import styles from './OfflineModal.module.scss';

const OfflineModal = () => {
    const { t } = useTranslation();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [sended, setSended] = useState(false);

    const dispatch = useDispatch();
    const offline = useSelector((state) => state.chat.offline);
    const user = useSelector((state) => state.user);

    const contact = useRef({ value: false });
    const message = useRef({ value: false });

    useEffect(() => {
        try {
            if (!offline) {
                if (!message.current.value) {
                    setIsOpen(false);
                }
            } else {
                setIsOpen(true);
            }
        } catch (error) {
            console.warn(error.message);
        }
    }, [offline, contact, message]);

    function closeModal() {
        setIsOpen(false);
    }

    const canSubscribe = () => {
        return isSupported() && typeof Notification !== 'undefined' && Notification.permission !== 'granted';
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (message.current && contact.current) {
            const text = `${t('OFFLINE_MESSAGE')} ${message.current.value} \n ${t('OFFLINE_CONTACT')} ${contact.current.value}`;
            dispatch(
                sendMessage({
                    text,
                    hidden: true,
                    kind: 'offline',
                    channelData: { email: contact.current.value },
                })
            );
            setSended(true);
        }
    };

    const askSubscribe = () => {
        if (canSubscribe()) {
            askPermission().then(function (permissionResult) {
                if (permissionResult === 'granted') {
                    subscribeToPush(user).catch((err) => {});
                }
            });
        }
    };

    const renderForm = () => (
        <Fragment>
            <div style={{ width: 220 }}>{t('NO_FREE_OPERATORS')}</div>
            <br />
            <form onSubmit={submitForm}>
                <label htmlFor="contact">{t('YOUR_EMAIL')}</label>
                <input
                    id="contact"
                    name="contact"
                    type="email"
                    placeholder={t('ENTER_YOUR_EMAIL')}
                    defaultValue={user.email}
                    ref={contact}
                />
                <br />
                <br />
                <label htmlFor="message">{t('MESSAGE')}</label>
                <textarea id="message" name="message" ref={message} />
                <div className={styles.buttons}>
                    {/* <button onClick={closeModal}>close</button> */}
                    <button>{t('SEND')}</button>
                </div>
            </form>
        </Fragment>
    );

    const renderSuccess = () => (
        <div>
            <div style={{ width: 220 }}>{t('MESSAGE_SENT')}</div>
            {canSubscribe() && (
                <div style={{ marginTop: '14px' }}>
                    <div style={{ marginBottom: '14px' }}>
                        {t('SUBSCRIBE_TO_PUSH')}
                    </div>
                    <div>
                        <button onClick={askSubscribe}>{t('SUBSCRIBE')}</button>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={styles.chatModal}
            contentLabel="Офлайн"
        >
            <div className={styles.attention}>
                <IosAlertOutline
                    style={{ background: '#FFF' }}
                    fontSize="48px"
                    color="#51718d"
                ></IosAlertOutline>
            </div>
            {sended ? renderSuccess() : renderForm()}
        </ReactModal>
    );
};

export default OfflineModal;
