import React, { useRef, useState, useEffect } from 'react';
import { Fragment } from 'react';
import IosAlertOutline from 'react-ionicons/lib/IosAlertOutline';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import IosStar from 'react-ionicons/lib/IosStar';
import { useTranslation } from 'react-i18next';

import { answerPoll, pollRequest } from '../../redux/Chat/actions';
import styles from './PollModal.module.scss';

const stars = [1, 2, 3, 4, 5];

const PollModal = () => {
  const { t } = useTranslation();

  const [modalIsOpen , setIsOpen] = useState(false);
  const [sended, setSended] = useState(false);
  const [rating, setRating] = useState(null);
  const [hovered, setHovered] = useState(0);

  const dispatch = useDispatch();
  const pollData = useSelector(state => state.chat.pollData);
  
  const comment = useRef({value: false});

  useEffect(() => {
    try {
      if (!pollData) {
        setIsOpen(false);
        setSended(false);
      } else {
        setIsOpen(true);
      }
    } catch (error) {
      console.warn(error.message);
    }
  }, [pollData]);

  function closeModal() {
    setIsOpen(false);
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (comment.current) {
      const payload = {
        rating: rating,
        comment: comment.current.value,
        answeredAt: new Date()
      };
      dispatch(answerPoll({ payload, pollId: pollData.pollId }));
      dispatch(pollRequest(null));
      setSended(true);
      setRating(0);
    }
  };

  const renderForm = () => (
    <Fragment>
      <div style={{width: 220}}>{pollData && pollData.pollTemplate && pollData.pollTemplate.text}</div>
      <br />
      <div className={styles.starsContainer}>
        {stars.map(item => {
          return <span className={styles.star} key={'star_icon_' + item} onMouseEnter={() => setHovered(item)} onMouseLeave={() => setHovered(0)}>
              <IosStar fontSize="24px" color={item <= rating || item <= hovered ? '#51718d' : '#cccccc'} onClick={() => setRating(item)}></IosStar>
          </span> 
        })}
      </div>
      <form onSubmit={submitForm}>
        <label htmlFor="comment">{t('YOUR_FEEDBACK')}</label>
        <textarea id="comment" name="comment" ref={comment} />
        <div className={styles.buttons}>
          <button>{t('SEND_FEEDBACK')}</button>
        </div>
      </form>
    </Fragment>
  );

  const renderSuccess = () => <div style={{width: 220}}>{t('FEEDBACK_SENT')}</div>

  return (
    <ReactModal ariaHideApp={false} isOpen={modalIsOpen} onRequestClose={closeModal} className={styles.chatModal} contentLabel={t('OFFLINE')} >
      <div className={styles.attention}>
        <IosAlertOutline style={{ background: '#FFF' }} fontSize="48px" color="#51718d"></IosAlertOutline>
      </div>
      {sended ? renderSuccess() : renderForm() }
    </ReactModal>
  );
};

export default PollModal;
